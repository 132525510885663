import ImageGallery from './components/ImageGallery'

function App() {
  console.log("App is rendering");
  return (
    <div className="app">
      <ImageGallery />
    </div>
  )
}

export default App